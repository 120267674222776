import "./navbar.css";
import React, { useEffect, useState } from "react";
// import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { FormControl, MenuItem, Paper, Select } from "@mui/material";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

import mainlogo from "./images/Group112.svg";
import search1 from "./images/search.svg";
import cartimg from "./images/bxs-cart-alt.svg";
import Cart from "../pages/Cart/Cart";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "#6EC177",
      borderColor: "#6EC177",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#6EC177",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#6EC177",
    },
    "& li.Mui-selected:hover": {
      background: "#6EC177",
    },
  },
}));

function NavBar({ handleChangeSearch, search, Iscart, setIscart, setSearch }) {
  const [isAbout, setIsAbout] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const id = useSelector((state) => state.productDetails);
  // console.log(id, "ProductList");
  //break points
  // const isTabletFloor = useMediaQuery({ query: "(max-width: 426px)" });
  const isMobileMid = useMediaQuery({ query: "(max-width: 428px)" });
  let location = useLocation();
  useEffect(() => {
    location.pathname === "/about" ? setIsAbout(false) : setIsAbout(true);
    // console.log("inside vikncodes_admin :^)");
    if (location.pathname === "/vikncodes_admin") {
      console.log("inside vikncodes_admin :^)");
      setIsAbout(false);
      setIsAdmin(true);
    }
  }, [location]);
  const isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const handleCartOpen = (name) => {
    setIscart(name);
    // console.log("888888888888888");
  };
  const handleNofProducts = (name) => {
    let totalproduct = id.ProductList.length;
    return totalproduct;
  };
  const handleTotalAmoutCart = (name) => {
    let totalAmount = 0;
    if (id.ProductList.length > 0) {
      // totalAmount = id.ProductList[0].data.SellingPrice;
      const totalSellingPrice = id.ProductList.reduce(
        (total, product) =>
          total + parseFloat(product.data.SellingPrice * product.Quantity),
        0
      );
      // console.log(
      //   totalSellingPrice,
      //   "totalSellingPricetotalSellingPricetotalSellingPricetotalSellingPrice"
      // );
      // totalAmount = id.ProductList[0].data.SellingPrice;
      totalAmount = Number(totalSellingPrice).toFixed(2);
    }
    return totalAmount;
  };
  // console.log(search, "888888888888888");

  //Handle Droapdown 🔽
  const [t, i18n] = useTranslation("common");
  const [values, setValues] = React.useState(["EN", "AR"]);
  const [selected, setSelected] = useState("EN");

  function handleChange(event) {
    // console.log(event.target);
    console.log(event.target.innerText);

    if (event.target.innerText === "AR") {
      setSelected("EN");
      i18n.changeLanguage("en");
    } else if (event.target.innerText === "EN") {
      setSelected("AR");
      i18n.changeLanguage("ar");
    }
  }
  
  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      navigate("/products", {
        state: { id: "search", objects: search, name: "search" },
      });
    }
  }
  const handleClearSearch = () => {
    setSearch("");
  };

  return (
    <div
      className="mainContainer"
      style={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        position: "fixed",
        top: "0",
        width: "100vw",
        maxHeight: "4.6rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // height: "9vh",
          padding: isTabletMid ? "18px 20px" : "18px 53px",
          whiteSpace: "nowrap",
        }}
        className="sub"
      >
        <div
          className=""
          style={{
            display: "flex",
            width: "110px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Link to="/">
              <img
                style={{ minWidth: "90px", maxWidth: "110px" }}
                src={mainlogo}
                className="mainlogo cursor-pointer"
                alt=""
                onClick={() => handleClearSearch()}
              />
            </Link>
          </div>
          {isAbout ? (
            <div
              // className="search"
              style={{
                minWidth: "381px",
                width: "28vw",
                paddingLeft: "2vw",
                position: "relative",
                display: isTabletMid ? "none" : "block",
              }}
            >
              <div>
                <input
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:ring-white  pr-9"
                  id="grid-last-name"
                  type="text"
                  placeholder={t("Search")}
                  onKeyDown={(e) => handleEnter(e) }
                  onChange={(e) => handleChangeSearch(e)}
                  value={search}
                />
              </div>
              <img
                src={search1}
                alt=""
                style={{
                  position: "absolute",
                  right: "5%",
                  top: "30%",
                  bottom: "60%",
                  left: "auto",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  navigate("/products", {
                    state: { id: "search", objects: search, name: "search" },
                  });
                }}
                // className="absolute right-2 left-auto top-[30%] bottom-[60%]"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className=""
          style={{
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: " auto auto ",
          }}
        >
          {/* Language button */}
          {/* <FormControl >
            <Select
            classes={{
              select: {
                  padding: '10.5px 14px'
              }
          }}
            sx={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // width: "8.5vw",
              height: "2.313rem",
              marginRight: "0.50rem",
              boxShadow: "none",
              backgroundColor: "#F1F1F1",
              padding: "0px",
              cursor: "pointer",
            }}

              value={selected}
              onChange={handleChange}
              inputProps={{
                name: "agent",
                id: "age-simple",
                style: {
                  display: "block",
                  fontSize: "11px",
                  fontWeight: "400",
                },
              }}
            >
              {values.map((value, index) => {
                return <MenuItem key={index} sx={{  }} value={value}>{value}</MenuItem>;
              })}
            </Select>
          </FormControl> */}

          
          {isAbout ? (
            <>
            <div>
            <button
              onClick={(e) => handleChange(e)}
              className=""
              style={{
                backgroundColor: "#F1F1F1",
                borderRadius: "7px",
                width: "34px",
                height: "34px",
                border: "none",
                font: "normal normal 600 12px/18px Poppins",
                placeItems: "center",
                marginRight: "0.6vw",
              }}
            >
              {selected}
            </button>
          </div>
            <div className="relative" onClick={(e) => handleCartOpen(!Iscart)}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "8.5vw",
                  // height: "5vh",
                  boxShadow: "none",
                  backgroundColor: "#F1F1F1",
                  padding: "3px 13px ",
                  cursor: "pointer",
                }}
              >
                <div className="wsd" style={{ dispaly: "flex" }}>
                  <p
                    style={{
                      font: " normal normal normal 9px/13px Poppins",
                      // font: " normal normal normal 0.7vw/2vh Poppins",
                      color: "#9A9A9A",
                      margin: "0",
                    }}
                    className=""
                  >
                    {handleNofProducts()} {t("product")}
                  </p>
                  <p
                    style={{
                      font: "normal normal 600  12px/18px Poppins",
                      // font: "normal normal 600  1vw / 2vh Poppins",
                      margin: "0",
                    }}
                    className=""
                  >
                    {t("QR")} {handleTotalAmoutCart()}
                  </p>
                </div>
                <div className="items-center w-8">
                  <div>
                    <img src={cartimg} alt="" />
                  </div>
                </div>
              </Paper>
            </div>
            </>
          ) : (
            ""
          )}

          {isAdmin && (
            <div>
            <button
              style={{
                backgroundColor: "#F1F1F1",
                borderRadius: "7px",
                paddingLeft: "10px",
                paddingRight: '10px',
                height: "34px",
                border: "none",
                font: "normal normal 600 12px/18px Poppins",
                placeItems: "center",
                marginRight: "0.6vw",
              }}
            >
              Admin Pannel
            </button>
          </div>
          )}
        </div>
      </div>
      <div
        className="search 2"
        style={{
          width: "100%",
          paddingLeft: "2vw",
          paddingRight: "2vw",
          position: "relative",
          // minWidth: "270px",
          backgroundColor: "white",
          paddingBottom: "18px",
          display: isTabletMid ? "block" : "none",
        }}
      >
        <div className="pr-4">
          <input
            className="appearance-none block w-full bg-gray-100 
            text-gray-700 border border-gray-200 rounded py-3 px-4 
            leading-tight focus:outline-none focus:bg-white focus:border-gray-500 
            pr-9 "
            id="grid-last-name"
            type="text"
            placeholder="Search"
            onChange={(e) => handleChangeSearch(e)}
            value={search}
          />
        </div>
        <img
          src={search1}
          alt=""
          style={{
            position: "absolute",
            right: "9%",
            top: "30%",
            bottom: "60%",
            left: "auto",
          }}
          // className="absolute right-2 left-auto top-[30%] bottom-[60%]"
        />
      </div>
      {Iscart === true ? (
        <div
          className={`${
            isMobileMid
              ? " absolute right-0 -mt-1 w-full"
              : "w-[430px] absolute right-0 -mt-1"
          }`}
        >
          <Cart handleCartOpen={handleCartOpen} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NavBar;

// const CustomTextField = styled(TextField)`
//   & label.Mui-focused {
//     color: white;
//   }
//   & .MuiOutlinedInput-root {
//     &.Mui-focused fieldset {
//       // border-color: white;
//     }
//   }
// `
